export default [
  {
    key: 'brandId',
    label: 'field.brand',
    rules: 'required',
    type: 'asyn-single-selection',
    repository: 'vehicleBrand',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'modelId',
    label: 'field.model',
    rules: 'required',
    type: 'asyn-single-selection',
    repository: 'vehicleModel',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'colorId',
    label: 'field.color',
    rules: '',
    type: 'asyn-single-selection',
    repository: 'vehicleColor',
    selectionKey: 'id',
    selectionLabel: 'name',
    clearable: true,
  },
  {
    key: 'year',
    label: 'field.year',
    // rules: 'required',
    type: 'year-picker',
    disableType: 'after',
  },
  {
    key: 'vinNumber',
    label: 'field.vinNumber',
    // rules: 'required|max:100',
    rules: 'max:100',
    type: 'text',
  },
  {
    key: 'engineNumber',
    label: 'field.engineNumber',
    // rules: 'required|max:100',
    rules: 'max:100',
    type: 'text',
  },
  {
    key: 'fuelTypeId',
    label: 'field.fuelType',
    type: 'asyn-single-selection',
    repository: 'vehicleFuelType',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'variant',
    label: 'field.variant',
    rules: 'max:100',
    type: 'text',
  },
  {
    key: 'engineCapacity',
    label: 'field.engineCapacity',
    rules: 'max:100',
    type: 'text',
  },
  {
    key: 'transmission',
    label: 'field.transmission',
    rules: '',
    type: 'single-selection',
    selectionKey: 'value',
    selectionLabel: 'text',
    options: [
      { text: 'AT', value: 'AT' },
      { text: 'MT', value: 'MT' },
    ],
  },
  {
    key: 'seatNumber',
    label: 'field.seatNumber',
    type: 'currency',
  },
  {
    key: 'carKey',
    label: 'field.carKey',
    type: 'text',
  },
  {
    key: 'drivetrain',
    label: 'field.drivetrain',
    rules: '',
    type: 'single-selection',
    selectionKey: 'value',
    selectionLabel: 'text',
    options: [
      { text: 'AWD', value: 'AWD' },
      { text: '4WD', value: '4WD' },
      { text: 'FWD', value: 'FWD' },
      { text: 'RWD', value: 'RWD' },
    ],
  },
  {
    key: 'arrivalDate',
    label: 'field.arrivalDate',
    rules: '',
    type: 'date-picker',
    disableType: 'before',
  },
  {
    key: 'note',
    label: 'field.note',
    rules: 'max:200',
    type: 'textarea',
    cols: 12,
  },
  {
    key: 'documents',
    label: 'field.documents',
    type: 'document-uploader',
    cols: 12,
  },
]
